.calendar {
  height: 90vh;
}

.schedule-container {
  display: flex;
  justify-content: space-around;
}

.new-hire-circle {
  background-color: #fdba4c;
}

.termination-circle {
  background-color: #007FFF;
}

.unpaid-leave-circle {
  background-color: #A9A9A9;
}

.transition-circle {
  background-color: #4CAF50;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 10px;
}

.mark-text {
  min-width: 100px;
  font-size: 14px;
  margin-left: 30px;
  font-weight: bold;
}