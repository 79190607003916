#device,
#region,
#employeeType {
  padding: 0.75rem !important;
}

.custom-date-picker {
  width: 100%;
  margin-top: 8px !important;
  margin-bottom: 4px !important;
}

.pink-switch .MuiSwitch-switchBase.Mui-checked:hover {
  background-color: rgba(233, 30, 99, 0.08) !important;
}

.pink-switch .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background-color: #e91e63 !important;
}

.pink-switch-label .MuiFormControlLabel-label {
  color: #e91e63 !important;
}

.error-message {
  color: #f44336;
  font-size: 0.75rem;
  margin: 0rem 0.5rem;
  font-weight: 400;
}

label {
  overflow: visible !important;
}