#region {
  padding: 0.75rem !important;
}

.custom-date-picker {
  width: 100%;
  margin-top: 8px !important;
  margin-bottom: 4px !important;
}

label {
  overflow: visible !important;
}

.error-message {
  color: #f44336;
  font-size: 0.75rem;
  margin: 0rem 0.5rem;
  font-weight: 400;
}